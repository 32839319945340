import './App.css';
import React from "react";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {useAudio} from "./useAudio.js";
import MusicButton from './MusicButton';

const firebaseConfig = {
  apiKey: "AIzaSyCumXRRNuscn0b87-SIP_X7ZrRdCHQEMiQ",
  authDomain: "moshalto-groundup-1.firebaseapp.com",
  projectId: "moshalto-groundup-1",
  storageBucket: "moshalto-groundup-1.appspot.com",
  messagingSenderId: "1004366712156",
  appId: "1:1004366712156:web:f61f4e7d5599d6ca090599",
  measurementId: "G-NLWJLMRNQM" 
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const url = 'https://firebasestorage.googleapis.com/v0/b/moshalto-groundup-1.appspot.com/o/dance_demo_orange.mp3?alt=media&token=40a79cea-ad72-4de2-bbe9-b88af2d770ce';
const url2 = 'https://firebasestorage.googleapis.com/v0/b/moshalto-groundup-1.appspot.com/o/BotLounge2.mp3?alt=media&token=25f52970-b573-41d7-8e3e-0463e7cccb5a';
const url3 = 'https://firebasestorage.googleapis.com/v0/b/moshalto-groundup-1.appspot.com/o/Magic_Omnibus_Sketch_Mix_1.mp3?alt=media&token=875b6d0e-b523-43d5-9e93-eea0fe6fcd38';
const url4 = 'https://firebasestorage.googleapis.com/v0/b/moshalto-groundup-1.appspot.com/o/Invention1.mp3?alt=media&token=33550c59-8e5f-4da4-8423-a4a00e963bc2';
const url5 = 'https://firebasestorage.googleapis.com/v0/b/moshalto-groundup-1.appspot.com/o/Invention5.mp3?alt=media&token=ab951e52-3238-4129-ae35-ddd14d176299';
const url6 = 'https://firebasestorage.googleapis.com/v0/b/moshalto-groundup-1.appspot.com/o/ISSDawn.mp3?alt=media&token=1c9fc12c-f13a-4ffe-9d5e-05f25f18155d';
const url7 = 'https://firebasestorage.googleapis.com/v0/b/moshalto-groundup-1.appspot.com/o/Saw.mp3?alt=media&token=cac066b1-b3c9-4a58-9530-4e839af3117c';
const url8 = 'https://firebasestorage.googleapis.com/v0/b/moshalto-groundup-1.appspot.com/o/WinterViolin.mp3?alt=media&token=64252f7d-62ef-488d-b19f-010b7904160f';
const url9 = 'https://firebasestorage.googleapis.com/v0/b/moshalto-groundup-1.appspot.com/o/Invention3.mp3?alt=media&token=5c33c78f-40a4-4c31-9a4a-074d09a4e5ef';

function App() {
  const songs =  [
    {url:url, color: 'brown'},
    {url:url2, color: 'purple'},
    {url:url3, color: 'orange'},
    {url:url4, color: 'blue'},
    {url:url5, color: 'green'},
    {url:url6, color: 'yellow'},
    {url:url7, color: 'pink'},
    {url:url8, color: 'grey'},
    {url:url9, color: 'peagreen'},
  ];
  return (
    <div className="App">
      <header className="App-header">
       <p>Recent Creations</p>
        <div className='container'>
          {songs.map((song)=>
            {return <MusicButton color={song.color} url={song.url}></MusicButton>}
            )
          }
        </div>
      </header>

    </div>
  );
}

export default App;
