import {useAudio} from "./useAudio.js";

function MusicButton(props) {
    const [playing, toggle] = useAudio(props.url);

    return (
        <button 
            className={`button ${props.color}`} 
            onClick={toggle} 
            type="button">
            {playing ?
            <div className="circle">
                <div className='left-bar'></div>
                <div className='right-bar'></div>
            </div> 
            : ''
            }
        </button>
    )
}

export default MusicButton;